import React from "react";
import { useMenuContext } from "../../context/MenuContext";
import { HamburgerArrow } from "react-animated-burgers";

import classnames from "classnames";
import styles from "./MenuToggle.module.css";

interface IProps {
  className?: string;
}
const MenuToggle: React.FC<IProps> = ({ className }) => {
  const { showMenu, toggleShowMenu } = useMenuContext();

  return (
    <HamburgerArrow
      isActive={showMenu}
      toggleButton={toggleShowMenu}
      barColor="#f7ffd8"
      className={classnames(styles.MenuToggle, className)}
      buttonWidth={24}
    />
  );
};

export default MenuToggle;
