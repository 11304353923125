import React, { useEffect, useState } from "react";
import styles from "./AvatarPicker.module.css";
import cx from "classnames";
import PageHeader from "../App/PageHeader";
import { useAuthUserContext } from "../../context/UserContext";
import Avatar from "./Avatar";
import { Link } from "react-router-dom";

interface IProps {}
const AvatarPicker: React.FC<IProps> = () => {
  const { API, user, updateMyProfile } = useAuthUserContext();
  const [avatars, setAvatars] = useState<string[]>([]);

  const pickAvatar = (avatar: string) => {
    updateMyProfile({ avatar });
  };

  useEffect(() => {
    API?.get("/avatars").then((res) => {
      res.data && setAvatars(res.data);
    });
  }, [API]);

  return (
    <div className={styles.AvatarPicker}>
      {avatars.map((avatar, idx) => (
        <span
          key={idx}
          className={cx(styles.Avatar, {
            [styles.Current]: avatar === user?.avatar,
          })}
          onClick={() => pickAvatar(avatar)}
        >
          <Avatar imageName={avatar} />
        </span>
      ))}
    </div>
  );
};

export const AvatarPickerPage: React.FC<IProps> = (props) => {
  return (
    <div className={styles.AvatarPickerPage}>
      <PageHeader>Choose your Avatar</PageHeader>
      <Link to="/profile/me">back to your profile</Link>
      <AvatarPicker />
    </div>
  );
};

export default AvatarPicker;
