import React from "react";
import { useMenuContext } from "../../context/MenuContext";

import styles from "./ActionBar.module.css";
import classnames from "classnames";

interface IProps {
  className: string;
}

const ActionBar: React.FC<IProps> = ({ className }) => {
  const { actionBar } = useMenuContext();
  if (!actionBar) {
    return <></>;
  }

  const { main, left, right } = actionBar;
  return (
    <div className={classnames(className, styles.ActionBar)}>
      {left && <div className={styles.Left}>{left}</div>}
      {main && <div className={styles.Main}>{main}</div>}
      {right && <div className={styles.Right}>{right}</div>}
    </div>
  );
};

export default ActionBar;
