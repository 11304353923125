import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { UserProvider } from "../../context/UserContext";
import { MenuProvider } from "../../context/MenuContext";
import { StatementProvider } from "../../context/StatementsContext";
import { ManifestoProvider } from "../../context/ManifestoContext";

import styles from "./App.module.css";
import "./global.css";

import StatementPage from "../Statement/StatementPage";
import Header from "./Header";
import LoginPage from "../User/LoginPage";
import ProfilePage from "../User/ProfilePage";
import CreatePage from "../User/CreatePage";
import Menu from "./Menu";
import LogoutPage from "../User/LogoutPage";
import MyProfilePage from "../User/MyProfilePage";
import AddStatementPage from "../Statement/AddStatementPage";
import ManifestoPage from "../Manifesto/ManifestoPage";
import ActionBar from "./ActionBar";
// import Backdrop from "../FX/Backdrop";
import RequestResetPage from "../User/RequestResetPage";
import ResetPasswordPage from "../User/ResetPasswordPage";
import HomePage from "./HomePage";
// import DashboardPage from "../Dashboard/DashboardPage";
import AboutPage from "./AboutPage";
import { ModalPopupProvider } from "../../context/ModalPopupContext";
import { BackFxProvider } from "../../context/BackFxContext";
import { AvatarPickerPage } from "../User/AvatarPicker";

function App() {
  return (
    <Router>
      <ModalPopupProvider>
        <MenuProvider>
          <UserProvider>
            <StatementProvider>
              <ManifestoProvider>
                <div className={styles.App}>
                  <BackFxProvider backdropClassName={styles.Backdrop}>
                    <Header className={styles.Header} />
                    <Menu className={styles.Menu} />
                    <div className={styles.Container}>
                      <Switch>
                        <Route exact path="/" component={HomePage} />
                        <Route exact path="/about" component={AboutPage} />
                        {/* <Route exact path="/dashboard" component={DashboardPage} /> */}
                        <Route
                          path="/statement/add"
                          exact
                          component={AddStatementPage}
                        />
                        <Route
                          path="/statement/:id"
                          component={StatementPage}
                        />
                        <Route path="/login" exact component={LoginPage} />
                        <Route path="/logout" exact component={LogoutPage} />
                        <Route path="/signup" exact component={CreatePage} />
                        <Route
                          path="/profile/me"
                          exact
                          component={MyProfilePage}
                        />
                        <Route path="/profile/:uuid" component={ProfilePage} />
                        <Route
                          path="/manifesto/:id"
                          component={ManifestoPage}
                        />
                        <Route
                          path="/password/forgot"
                          component={RequestResetPage}
                        />
                        <Route
                          path="/password/reset"
                          component={ResetPasswordPage}
                        />
                        <Route
                          path="/avatarpicker"
                          component={AvatarPickerPage}
                        />
                        {/* <Route
                      path="/password/update"
                      component={UpdatePasswordPage}
                    /> */}
                      </Switch>
                    </div>
                    <ActionBar className={styles.ActionBar} />
                  </BackFxProvider>
                </div>
              </ManifestoProvider>
            </StatementProvider>
          </UserProvider>
        </MenuProvider>
      </ModalPopupProvider>
    </Router>
  );
}

export default App;
