import React, { useEffect } from "react";

import styles from "./Menu.module.css";
import classnames from "classnames";
import { useMenuContext } from "../../context/MenuContext";
import { useAuthUserContext } from "../../context/UserContext";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
// import Avatar from "../User/Avatar";
// import useRandomStatement from "../../hooks/useRandomStatement";
import { useStatementsContext } from "../../context/StatementsContext";

interface IProps {
  className?: string;
}

const Menu: React.FC<IProps> = ({ className, children }) => {
  const { showMenu, setShowMenu } = useMenuContext();
  const { loggedIn } = useAuthUserContext();
  // const match = useRouteMatch<{ id: string }>("/statement/:id");
  // let curStatementId = undefined;
  // if (match?.params.id) {
  //   curStatementId = parseInt(match.params.id);
  // }
  // const randomStatement = useRandomStatement(curStatementId);
  const { stack } = useStatementsContext();
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      setShowMenu(false);
    });
  }, [history, setShowMenu]);

  return (
    <div
      className={classnames(className, styles.Menu, {
        [styles.Show]: showMenu,
      })}
      // onClick={() => setShowMenu(false)}
    >
      <ul>
        <li>
          <Link to="/about">About</Link>
        </li>

        <li>
          <Link to={`/statement/${stack?.current?.id ?? 1}`}>
            Browse Statements
          </Link>
        </li>
        {!loggedIn && (
          <>
            <li>
              <Link to="/login">Login</Link>
            </li>
          </>
        )}
        {loggedIn && (
          <>
            <li>
              <Link to="/statement/add">Contribute Statement</Link>
            </li>
            <li>
              <Link to="/manifesto/me">My manifesto</Link>
            </li>
            {/* <li>
              <Link to="/dashboard">Dashboard</Link>
            </li> */}
            <li className={styles.AccountMenuItem}>
              <Link to="/profile/me">Account</Link>
            </li>
            <li>
              <Link to="/logout">Logout</Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default Menu;
