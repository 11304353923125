import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios, { AxiosInstance } from "axios";
import { useHistory } from "react-router-dom";
import useLocalStorage from "react-use-localstorage";
import { ManifestoId } from "./ManifestoContext";
import { StatementId } from "./StatementsContext";
import { CommentId } from "../services/ReactionService";

export type UserID = string;

export interface IUser {
  uuid: UserID;
  name: string;
  email?: string;
  gravatar?: string;
  avatar?: string;
  manifestoIds?: ManifestoId[];
  lastComments?: { statementId: StatementId; commentId: CommentId }[];
}

interface IAuthUserContext {
  user?: IUser;
  loggedIn: boolean;
  error?: string;
  statusMessage?: string;
  login: (email: string, password: string, redirect?: string) => void;
  logout: () => void;
  create: (email: string, password: string, redirect?: string) => void;
  updateMyProfile: ({
    name,
    email,
    avatar,
  }: {
    name?: string;
    email?: string;
    avatar?: string;
  }) => void;
  exchangeResetToken: (resetToken: string) => void;
  updatePassword: (password: string) => void;
  API?: AxiosInstance;
}
const UserContext = createContext<IAuthUserContext | undefined>(undefined);

interface UserProviderProps {}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [statusMessage, setStatusMessage] = useState<string | undefined>(
    undefined
  );
  const [accessToken, setAccessToken] = useLocalStorage("accessToken");
  const history = useHistory();
  const [API, setAPI] = useState<AxiosInstance>();

  useEffect(() => {}, []);

  useEffect(() => {
    const api = axios.create({ baseURL: "https://caim.app/api" });
    api.interceptors.request.use((config) => {
      setError(undefined);
      setStatusMessage(undefined);
      return config;
    });
    api.interceptors.response.use(
      (response) => {
        if (response?.data?.message) {
          setStatusMessage(response.data.message);
        }
        return response;
      },
      (err) => {
        console.warn("Global error handling", err, err.message, err.response);
        let msg = err?.response?.data?.message;
        if (typeof msg !== "string") {
          msg = undefined;
        }
        setError(
          msg || err.message || `Unknown error ${err?.response?.status ?? ""}`
        );
        // don't throw up again
      }
    );
    if (accessToken) {
      api.defaults.headers["Authorization"] = "Bearer " + accessToken;
      api.get("/user/profile/me").then((res) => {
        setUser(res?.data || undefined);
      });
      setLoggedIn(true);
    } else {
      api.defaults.headers["Authorization"] = undefined;
    }
    setAPI(() => api);
  }, [accessToken]);

  const login = useCallback(
    async (email: string, password: string, redirect?: string) => {
      // try {
      const res = await API!.post("/user/login", { email, password });
      if (res?.data) {
        setAccessToken(res.data.accessToken);
        setUser(res.data.user);
        history.push(redirect || "/profile/me");
      }
      // } catch (ex) {
      //   console.error(ex);
      //   setError(ex.message);
      // }
    },
    [API, history, setAccessToken]
  );

  const logout = async () => {
    setLoggedIn(false);
    setUser(undefined);
    setAccessToken("");
  };

  const create = useCallback(
    async (email: string, password: string, redirect?: string) => {
      const res = await API!.post("/user/signup", {
        email,
        password,
      });
      if (res?.data) {
        setAccessToken(res.data.accessToken);
        setUser(res.data.user);
        history.push(redirect || "/profile/me");
      }
    },
    [API, setAccessToken, history]
  );

  const updateMyProfile = useCallback(
    async ({ name, email, avatar }) => {
      const res = await API!.post("/user/profile", { name, email, avatar });
      if (res?.data) {
        setUser(res.data);
      }
    },
    [API]
  );

  const exchangeResetToken = useCallback(
    async (resetToken: string) => {
      // /user/login/token
      const res = await API!.post("/user/login/token", {
        reset_token: resetToken,
      });
      if (res?.data) {
        setAccessToken(res.data.accessToken);
        setUser(res.data.user);
      }
    },
    [API, setAccessToken]
  );

  const updatePassword = useCallback(
    async (password: string) => {
      //
      await API!.post("/user/password", { password });
    },
    [API]
  );

  return (
    <UserContext.Provider
      value={{
        API,
        user,
        loggedIn,
        error,
        statusMessage,
        login,
        logout,
        create,
        updateMyProfile,
        exchangeResetToken,
        updatePassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useAuthUserContext = () => useContext(UserContext)!;

export default UserContext;
