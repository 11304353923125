import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuthUserContext } from "../../context/UserContext";
import ContentSection from "../App/ContentSection";
import PageHeader from "../App/PageHeader";
import Avatar from "./Avatar";

import styles from "./MyProfilePage.module.css";

const MyProfilePage: React.FC = () => {
  const { user, updateMyProfile } = useAuthUserContext();
  const [name, setName] = useState<string | undefined>("");
  const [email, setEmail] = useState<string | undefined>("");

  useEffect(() => {
    setName(user?.name || "");
    setEmail(user?.email || "");
  }, [user]);

  if (!user) {
    return (
      <div className={styles.MyProfilePage}>
        <PageHeader>No user</PageHeader>
      </div>
    );
  }

  return (
    <div className={styles.MyProfilePage}>
      <PageHeader>Edit Profile</PageHeader>

      <Avatar user={user} />
      <Link to="/avatarpicker" className="picker">
        Choose your avatar
      </Link>
      <p className={styles.gravatar_caption}>
        <em>
          Your avatar is taken from{" "}
          <a
            href="http://gravatar.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            gravatar.com
          </a>
        </em>
      </p>
      <ContentSection>
        <p className={styles.profile_edit_field}>
          <label>Name</label>
          <input
            type="text"
            value={name}
            onChange={(evt) => setName(evt.target.value)}
          />
        </p>
        <p className={styles.profile_edit_field}>
          <label>Email</label>
          <input
            type="text"
            value={email}
            onChange={(evt) => setEmail(evt.target.value)}
          />
        </p>
        <button onClick={(evt) => updateMyProfile({ name, email })}>
          update
        </button>
      </ContentSection>
    </div>
  );
};

export default MyProfilePage;
