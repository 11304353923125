import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthUserContext } from "../../context/UserContext";
import useQuery from "../../hooks/useQuery";
import PageHeader from "../App/PageHeader";

import styles from "./CreatePage.module.css";

interface CreatePageProps {}

const CreatePage: React.FC<CreatePageProps> = () => {
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const { create, error } = useAuthUserContext();
  const redirectUrl = useQuery("redirect");

  const doSignup = () => {
    email && password && create(email, password, redirectUrl);
  };

  // useEffect(() => {
  // console.log("useEffect because user");
  // }, [user]);

  return (
    <div className={styles.CreatePage}>
      <PageHeader>Sign Up</PageHeader>
      {error && <p>{error}</p>}
      <div className={styles.LoginForm}>
        <input
          placeholder="email"
          value={email}
          onChange={(evt) => setEmail(evt.target.value)}
        />
        <input
          placeholder="password"
          value={password}
          type="password"
          onChange={(evt) => setPassword(evt.target.value)}
        />
        <button disabled={!email || !password} onClick={doSignup}>
          Sign Up
        </button>
        <Link className={styles.LoginLink} to={`/login`}>
          Login
        </Link>
      </div>
    </div>
  );
};

export default CreatePage;
