import React from "react";
import { Link, useParams } from "react-router-dom";
import { useAuthUserContext, UserID } from "../../context/UserContext";
import useUserProfile from "../../hooks/useUserProfile";
import ContentSection from "../App/ContentSection";
import PageHeader from "../App/PageHeader";
import Avatar from "./Avatar";

import styles from "./ProfilePage.module.css";

interface RouteParams {
  uuid: UserID;
}
const ProfilePage: React.FC = () => {
  const { uuid } = useParams<RouteParams>();
  const { user: loggedInUser } = useAuthUserContext();
  const userProfile = useUserProfile(uuid);

  if (!userProfile) {
    return <div className={styles.ProfilePage}>No user</div>;
  }

  return (
    <div className={styles.ProfilePage}>
      <PageHeader>Profile</PageHeader>
      <Avatar user={userProfile} />
      <ContentSection>
        <p>Name: {userProfile.name}</p>
        {userProfile.manifestoIds?.length && (
          <p>
            {userProfile.manifestoIds.map((id) => (
              <Link key={id} to={`/manifesto/${id}`}>
                Manifesto
              </Link>
            ))}
          </p>
        )}
        {loggedInUser?.uuid && loggedInUser.uuid === userProfile.uuid && (
          <p>
            This is your profile <Link to="/profile/me">edit</Link>
          </p>
        )}
      </ContentSection>
    </div>
  );
};

export default ProfilePage;
