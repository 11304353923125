import React from "react";
import { IUser } from "../../context/UserContext";
import { Link } from "react-router-dom";

import styles from "./Avatar.module.css";
import cx from "classnames";

interface IProps {
  imageName?: string;
  user?: IUser;
  className?: string;
}
const Avatar: React.FC<IProps> = ({ user, className, imageName }) => {
  const link = user ? `/profile/${user.uuid}` : "/login";
  let imgSrc = "";
  if (imageName) {
    imgSrc = `avatars/${imageName}`;
  } else if (user && user.avatar) {
    imgSrc = `/avatars/${user.avatar}`;
  } else if (user && user.gravatar) {
    // different fallback?
    imgSrc = `https://www.gravatar.com/avatar/${user.gravatar}?s=200&d=robohash`;
  } else {
    // no user known (anymore), different fallback?
    imgSrc = `https://www.gravatar.com/avatar/${Math.round(
      Math.random() * 100000
    )}?s=100&d=robohash`;
  }
  // const imgSrc = user
  //   ? `https://www.gravatar.com/avatar/${user.gravatar}?s=200&d=robohash`
  //   : `https://www.gravatar.com/avatar/${Math.round(
  //       Math.random() * 100000
  //     )}?s=100&d=robohash`;
  if (user) {
    return (
      <Link to={link} className={cx(styles.link_gravatar, className)}>
        <img className={styles.AvatarImage} src={imgSrc} alt="avatar" />
      </Link>
    );
  } else {
    return <img className={styles.AvatarImage} src={imgSrc} alt="avatar" />;
  }
};

export default Avatar;
