import React from "react";

import MenuToggle from "./MenuToggle";

import styles from "./Header.module.css";
import classnames from "classnames";
import { Link, useLocation } from "react-router-dom";
// import ParrotLogoPath, {
//   ReactComponent as ParrotLogo,
// } from "../../assets/parrot.svg";
import ParrotLogo from "../../assets/parrot.png";
import Blur from "../FX/Blur";

interface IProps {
  className?: string;
}

const Header: React.FC<IProps> = ({ className }) => {
  const location = useLocation();
  const showLogo = location.pathname !== "/";

  return (
    <div className={classnames(className, styles.Header)}>
      <MenuToggle className={styles.MenuToggle} />
      {showLogo && (
        <Blur offset={[5, -5]} scale={1.1} className={styles.LogoLink}>
          <Link to="/">
            <img src={ParrotLogo} className={styles.Logo} alt="CAIM Logo" />
          </Link>
        </Blur>
      )}
    </div>
  );
};

export default Header;
