import { useState, useEffect } from "react";
import { useManifestoContext, IManifesto } from "../context/ManifestoContext";
import {
  IStatement,
  OptionalIStatement,
  StatementId,
  useStatementsContext,
} from "../context/StatementsContext";
import { useAuthUserContext } from "../context/UserContext";

const useManifesto = (id: string) => {
  const { myManifesto } = useManifestoContext();
  const { getStatement } = useStatementsContext();
  const { API } = useAuthUserContext();
  const [manifesto, setManifesto] = useState<IManifesto>();
  const [statementIds, setStatementIds] = useState<StatementId[]>();

  useEffect(() => {
    if (id === "me") {
      setManifesto(myManifesto);
    }
  }, [id, myManifesto]);

  useEffect(() => {
    if (!statementIds) {
      return;
    }
    setManifesto((_manifesto) => {
      if (!_manifesto) {
        return _manifesto;
      }
      let manifesto: IManifesto = { ..._manifesto };
      manifesto.statements = statementIds
        .map((id: number) => {
          return getStatement(id);
        })
        .filter((item: OptionalIStatement) => !!item) as IStatement[];
      return manifesto;
    });
  }, [statementIds, getStatement]);

  useEffect(() => {
    if (id !== "me") {
      API &&
        API.get(`/manifesto/${id}`).then((res) => {
          const ids = res.data?.statements;
          const data = res.data;
          data.statements = [];
          setManifesto(data);
          setStatementIds(ids);
        });
    }
  }, [id, API]);

  return { manifesto, reload: () => {} };
};

export default useManifesto;
