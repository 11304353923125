import React, { useEffect, useState } from "react";

import styles from "./AddStatementPage.module.css";
// import classnames from "classnames";
import { useHistory } from "react-router-dom";
import useAddStatement from "../../hooks/useAddStatement";
import { useManifestoContext } from "../../context/ManifestoContext";
import PageHeader from "../App/PageHeader";
import ContentSection from "../App/ContentSection";

const AddStatementPage: React.FC = () => {
  const [text, setText] = useState<string>("");
  const [source, setSource] = useState<string>("");
  const [sourceLink, setSourceLink] = useState<string>("");
  // const [addToMyManifesto, _setAddToMyManifesto] = useState(true);
  const addToMyManifesto = true;
  const { add, statement } = useAddStatement();
  const { add: manifestoAdd } = useManifestoContext();
  const history = useHistory();

  useEffect(() => {
    if (statement) {
      if (addToMyManifesto) {
        manifestoAdd(statement);
      }
      history.push(`/statement/${statement.id}`);
    }
  }, [statement, addToMyManifesto, manifestoAdd, history]);

  return (
    <div className={styles.AddStatementPage}>
      <PageHeader>Contribute Statement</PageHeader>
      <ContentSection>
        <p>Write a new statement, others can also add it to their manifesto.</p>
        <textarea value={text} onChange={(evt) => setText(evt.target.value)} />
        <label>
          Source (optional)
          <input
            placeholder="Source"
            value={source}
            onChange={(evt) => setSource(evt.target.value)}
          />
        </label>
        <label>
          Source link (optional)
          <input
            placeholder="URL"
            value={sourceLink}
            onChange={(evt) => setSourceLink(evt.target.value)}
          />
        </label>
        {/* <label>
          Add to my manifesto
          <input
            type="checkbox"
            checked={addToMyManifesto}
            onChange={(evt) => setAddToMyManifesto(evt.target.checked)}
          />
        </label> */}
        <button disabled={!text} onClick={() => add(text, source, sourceLink)}>
          Add statement to the pool
        </button>
      </ContentSection>
    </div>
  );
};

export default AddStatementPage;
